import React from 'react'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBullseye,
    faCloud,
    faBox
} from '@fortawesome/free-solid-svg-icons'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { format } from 'react-string-format'
import RichText from '../components/RichText'


class ServicesOverviewRoute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            index: 0,
            hash: ""
        };
    }

    setValue = (indexValue, hashValue) => {
        this.setState({
            index: indexValue,
            hash: hashValue
        });
    };

    render() {
        const servicesOverviewData = this.props.data
        const postfix = servicesOverviewData.kontentItemSiteMetadata.elements.title.value
        const kontentItemServicesOverviewPage = servicesOverviewData.kontentItemServicesOverviewPage
        const servicesOverviewElements = kontentItemServicesOverviewPage.elements
        const pageTitle = servicesOverviewElements.title.value
        const pageSubtitle = servicesOverviewElements.subtitle.value
        const metaDescription = servicesOverviewElements.meta_description.value

        const serviceItems = servicesOverviewElements.services.value

        var currentHash = this.props.location.hash
        const items = []
        serviceItems.forEach(serviceItem => {
            items.push({ hash: "#" + serviceItem.elements.slug.value, title: serviceItem.elements.title.value })
        })
        var currentHashIndex = currentHash != "" ? items.findIndex(el => el.hash == currentHash) : 0

        return (
            <Layout>
                <div>
                    <Meta title={pageTitle} postfix={postfix} description={metaDescription} />
                    <Header />
                    <PageHeading data={pageSubtitle} />
                    <section className="services tabs">
                        <Tabs className="tabs" selectedIndex={(this.state.index == 0 && currentHashIndex != -1) ? currentHashIndex : this.state.index} onSelect="">
                            <nav className="tabs__header">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <TabList className="tabs__labels">
                                                <div className="tab-list tab-list--desktop">
                                                    {serviceItems &&
                                                        serviceItems.map((serviceItem, index) => (
                                                            <Tab
                                                                key={serviceItem.elements.slug.value}
                                                                onClick={() => this.setValue(index, serviceItem.elements.title.value, '#' + serviceItem.elements.slug.value)}
                                                                className="col-sm-4 tabs__label"
                                                            >
                                                                <a href={`#${serviceItem.elements.slug.value}`} data-tracking-label={format('Service_Tab_{0}', serviceItem.elements.title.value)}>
                                                                    <FontAwesomeIcon icon={serviceItem.elements.icon.value == "cloud" ? faCloud : serviceItem.elements.icon.value == "target" ? faBullseye : faBox} size="1x" />
                                                                    <h2 className="service__title">{serviceItem.elements.title.value}</h2>
                                                                    <p className="service__description">{serviceItem.elements.short_description.value}</p>
                                                                </a>
                                                            </Tab>
                                                        ))}
                                                </div>
                                                <div className="tab-list tab-list--dropdown">
                                                    <select
                                                        className="tab-list__select"
                                                        onChange={(e) => {
                                                            this.setValue(e.target.selectedIndex, e.nativeEvent.target[e.target.selectedIndex].value);
                                                            this.props.location.hash = e.nativeEvent.target[e.target.selectedIndex].value;
                                                            window.history.pushState({}, '', e.nativeEvent.target[e.target.selectedIndex].value);
                                                        }}
                                                        value={this.state.hash != "" ? this.state.hash : this.props.location.hash}
                                                    >
                                                        {serviceItems &&
                                                            serviceItems.map((serviceItem, index) => (
                                                                <option key={serviceItem.elements.slug.value} value={`#${serviceItem.elements.slug.value}`}>{serviceItem.elements.title.value}</option>
                                                            ))}
                                                    </select>
                                                </div>

                                            </TabList>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                            <div className="tabs__body">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {serviceItems &&
                                                serviceItems.map((serviceItem, index) => (
                                                    <TabPanel id={serviceItem.elements.slug.value}>
                                                        <div className="tabs__content tabs__content--active">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <RichText data={serviceItem.elements.long_description} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </section>
                    <Footer />
                </div>
            </Layout >
        )
    }
}

export default ServicesOverviewRoute

export const pageQuery = graphql`
query ServicesOverviewQuery($codename: String!) {
  kontentItemSiteMetadata(system: { codename: { eq: "site_metadata" } }) {
    elements {
      title {
        value
      }
    }
  }
  kontentItemServicesOverviewPage(system: { codename: { eq: $codename } }) {
    id
    system {
      id
    }
    elements {
      slug {
        value
      }
      meta_description {
        value
      }
      title {
        value
      }
      subtitle {
        value
      }
      services {
        value {
          ... on kontent_item_service_item {
            id
            elements {
              title {
                value
              }
              slug {
                value
              }
              icon {
                value
              }
              short_description {
                value
              }
              long_description {
                value
                links {
                  link_id
                  url_slug
                  type
                }
                images {
                  image_id
                  description
                  url
                  fluid(maxWidth: 350) {
                    ...KontentAssetFluid
                  }
                  fixed(height: 700) {
                    aspectRatio
                    src
                    ...KontentAssetFixed
                  }
                }
                modular_content {
                  ... on kontent_item_component_images_wrapper {
                    id
                    elements {
                      description {
                        value
                      }
                      photos {
                        value {
                          fluid(maxWidth: 350) {
                            aspectRatio
                            ...KontentAssetFluid
                          }
                          fixed(height: 700) {
                            aspectRatio
                            src
                            ...KontentAssetFixed
                          }
                          description
                          url
                        }
                      }
                    }
                    system {
                      codename
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  allKontentItemServiceItem(filter: { preferred_language: { eq: "en-US" } }) {
    nodes {
      id
      elements {
        slug {
          value
        }
        title {
          value
        }
      }
    }
  }
}


`
